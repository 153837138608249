import * as actions from './actions';
import * as getters from './getters';
import * as mutations from './mutations';

const state = {
  paymentMethods: null,
  payUrl: null,
  rates: {}
};

export const pay = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
