import {clientApi} from '@/utils/clientApi';

export const fetchRate = async ({commit}, currency) => {
    try {
        const response = await clientApi('get', `payment/rate?currency=${currency}`);
        let {data} = response.data;
        if (data && data.hasOwnProperty('rate')) {
            commit('pay/SET_RATE', {currency, value: data.rate}, {root: true});
        }
    } catch (error) {
        console.log('Error while get estimate', error);
    }
};
